import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { openSnsPop } from '@/common/GlobalFunction'
import { parse } from 'url'
import * as AppleID from '../../assets/static/js/appleid.auth'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    id: '',
    password: '',
    isAuto: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    kakaoPageUrl: '',
    naverPageUrl: '',
    googlePageUrl: '',
    applePageUrl: '',
    appleQueries: computed(() => {
      const uri = state.applePageUrl && parse(state.applePageUrl, true)
      return uri && uri.query
    }),
    result: {}
  })

  const fnSignUp = async () => {
    await router.push({ path: '/regist/terms' })
  }
  const fnFind = async () => {
    await router.push({ path: '/find/id/form' })
  }
  const fnLogin = async () => {
    if (!isValid()) return
    try {
      const params = {}
      params.id = state.id
      params.password = state.password
      const res = await proxy.$UserSvc.postLogin(params)
      state.result = proxy.$_.cloneDeep(res)
      if (
        res.resultCode !== '0000' &&
        res.resultCode !== '0002' &&
        res.resultCode !== '0003'
      ) {
        if (res.resultCode === '0004') {
          const params1 = {}
          params1.userId = state.id
          await router.push({ name: 'login-mail-index', params: params1 })
        } else {
          alert(res.resultMsg)
        }
        return
      } else {
        if (res.resultCode !== '0000') {
          alert(res.resultMsg)
        }
      }
      await dispatch('user/updateIsAuto', { isAuto: state.isAuto })
    } catch (e) {
      console.error(e)
    }
    await fnCheckRequestExtension()
  }
  const fnCheckRequestExtension = async () => {
    if (
      !proxy.$Util.isEmpty(state.result.autoExtensionPopup) &&
      state.result.autoExtensionPopup
    ) {
      await dispatch('user/updateIsAutoExtensionPopup', {
        isAutoExtensionPopup: true
      })
    }
    if(route.query.wizzroom) {
      location.href = `${route.query.wizzroom}/#/callback`
    } else {
      await router.push({ path: '/main', query: { isRoot: 1 } })
    }
  }
  const fnSnsLogin = async (loginType = 0) => {
    let authorizePageUri = ''
    if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value) {
      authorizePageUri = state.kakaoPageUrl
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value) {
      authorizePageUri = state.naverPageUrl
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.value) {
      authorizePageUri = state.googlePageUrl
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.APPLE.value) {
      AppleID.auth.init({
        clientId: state.appleQueries.client_id,
        redirectURI: state.appleQueries.redirect_uri,
        scope: state.appleQueries.scope,
        usePopup: false
      })
      return AppleID.auth.signIn()
    }
    const newWindow = window.open(authorizePageUri)
    openSnsPop(newWindow, fnCallback)
  }
  const fnCallback = async params => {
    state.result = proxy.$_.cloneDeep(params)
    if (params.resultCode === '0000') {
      // 로그인한 회원데이터
      await dispatch('user/signIn', {
        authorization: params.authorization,
        data: params
      })
      await dispatch('user/updateIsAuto', {
        isAuto: proxy.$ConstCode.BOOLEAN_VALUE.TRUE
      })
      await fnCheckRequestExtension()
      // await router.replace({ path: '/main', query: { isRoot: 1 } })
    } else if (params.resultCode === '0006') {
      const registerForm = Object.assign(getters['user/getRegisterForm'])
      registerForm.loginType = Number(params.loginType)
      // registerForm.nick = params.nick
      registerForm.id = params.snsId
      await dispatch('user/updateRegisterForm', { registerForm })
      await router.push({ path: '/regist/terms' })
    } else {
      if (!proxy.$Util.isEmpty(params.resultCode)) {
        alert(params.resultMsg)
      } else {
        alert('인증에 실패하였습니다.')
      }
    }
  }
  const isValid = () => {
    if (!proxy.$Util.isValidEmail(state.id)) {
      alert('올바른 이메일 주소를 등록해주세요.')
      return false
    }
    return true
  }
  const fnGetSnsPageUrl = async (loginType = 1) => {
    const params = {}
    params.login_type = loginType
    params.rtnUrl = `${window.location.origin}/oauth`
    const res = await proxy.$UserSvc.postOauthLogin(params)
    if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value) {
      state.kakaoPageUrl = res.authorizePageUri
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value) {
      state.naverPageUrl = res.authorizePageUri
    } else if (loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.value) {
      state.googlePageUrl = res.authorizePageUri
    }
    else if(loginType === proxy.$ConstCode.SNS_LOGIN_TYPE.APPLE.value) {
      state.applePageUrl = res.authorizePageUri
    }
  }
  const init = async () => {
    await dispatch('user/clearRegisterForm')
    if(route.query.wizzroom) {
      if(!proxy.$Util.isEmpty(state.userData)) {
        location.href = `${route.query.wizzroom}/#/callback`
      }
    }
    if (!proxy.$Util.isEmpty(state.userData)) {
      await router.replace({ path: '/main', query: { isRoot: 1 } })
      return
    }
    const getUrlPromises = []
    for (let loginType of Object.values(proxy.$ConstCode.SNS_LOGIN_TYPE)) {
      getUrlPromises.push(fnGetSnsPageUrl(loginType.value))
    }

    await Promise.all(getUrlPromises)
  }
  init()
  return { ...toRefs(state), fnSignUp, fnFind, fnLogin, fnSnsLogin, fnCallback }
}
