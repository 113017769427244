<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="form_wrap">
            <div class="form login">
              <!--'로그인: login'-->
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="아이디를 입력해 주세요."
                        v-model="id"
                      />
                    </label>
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 입력해 주세요."
                        v-model="password"
                      />
                    </label>
                    <button class="btn" @click="fnLogin">로그인</button>
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                        :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                        v-model="isAuto"
                        :checked="isAuto === $ConstCode.BOOLEAN_VALUE.TRUE"
                      />
                      <span class="label"><span>로그인 상태 유지</span></span>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_in">
                    <div class="login_btn_wrap">
                      <button @click="fnSignUp">회원가입</button>
                      <button @click="fnFind">아이디/비밀번호 재발급</button>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_in">
                    <div class="openid">
                      <div class="openid_ttl">openID로 로그인</div>
                      <div class="openid_bts">
                        <button
                          class="kakao"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.KAKAO.value)
                          "
                        >
                          <span>카카오 계정으로 로그인</span>
                        </button>
                        <button
                          class="naver"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.NAVER.value)
                          "
                        >
                          <span>네이버 계정으로 로그인</span>
                        </button>
                        <button
                          class="google"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.GOOGLE.value)
                          "
                        >
                          <span>구글 계정으로 로그인</span>
                        </button>
                        <button
                          class="apple"
                          @click="
                            fnSnsLogin($ConstCode.SNS_LOGIN_TYPE.APPLE.value)
                          "
                        >
                          <span>Apple로 로그인</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'login-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
